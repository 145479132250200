import React from 'react'

const PayPaypal = () => (
<svg width="40px" height="28px" viewBox="0 0 40 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-1369.000000, -281.000000)" fillRule="nonzero">
            <g id="paypal-card" transform="translate(1369.000000, 281.000000)">
                <path d="M3.17647237,26.6876261 C2.33163843,26.6954565 1.51908654,26.3633683 0.921672145,25.765954 C0.324257747,25.1685396 -0.007830426,24.3559877 0,23.5111537 L0,3.17647237 C-0.007830426,2.33163843 0.324257747,1.51908654 0.921672145,0.921672145 C1.51908654,0.324257747 2.33163843,-0.007830426 3.17647237,0 L36.2201757,0 C37.0650096,-0.007830426 37.8775615,0.324257747 38.4749759,0.921672145 C39.0723903,1.51908654 39.4044785,2.33163843 39.396648,3.17647237 L39.396648,23.5111537 C39.4044785,24.3559877 39.0723903,25.1685396 38.4749759,25.765954 C37.8775615,26.3633683 37.0650096,26.6954565 36.2201757,26.6876261 L3.17647237,26.6876261 L3.17647237,26.6876261 Z" id="Path" fill="#F2F2F2"></path>
                <path d="M35.7030983,1.23127673 C37.0573485,1.23127673 38.1653713,2.33929956 38.1653713,3.6935497 L38.1653713,23.3917335 C38.1653713,24.7459836 37.0573485,25.8540065 35.7030983,25.8540065 L3.6935497,25.8540065 C2.33929956,25.8540065 1.23127673,24.7459836 1.23127673,23.3917335 L1.23127673,3.6935497 C1.23127673,2.33929956 2.33929956,1.23127673 3.6935497,1.23127673 L35.7030983,1.23127673 Z M35.7030983,0.000140239305 L3.6935497,0.000140239305 C1.72373132,0.000140239305 0.000140239305,1.60061767 0.000140239305,3.6935497 L0.000140239305,23.3917335 C0.000140239305,25.4846655 1.60061767,27.0851429 3.6935497,27.0851429 L35.7030983,27.0851429 C37.7960304,27.0851429 39.3965078,25.4846655 39.3965078,23.3917335 L39.3965078,3.6935497 C39.3965078,1.60061767 37.7960304,0.000140239305 35.7030983,0.000140239305 Z" id="Shape" fill="#D8D8D8"></path>
                <g id="Group-4" transform="translate(14.078853, 4.693550)">
                    <path d="M4.39150417,15.8134268 L4.67670411,13.9454682 L4.04157799,13.930298 L1.01259276,13.930298 L3.11821789,0.151702099 C3.12477287,0.109968083 3.14548431,0.0717590474 3.17687603,0.0434879349 C3.20757195,0.0162922616 3.24711435,0.00119425332 3.28812424,0.00101134732 L8.39745092,0.00101134732 C10.092469,0.00101134732 11.2625979,0.365096384 11.874463,1.08416433 C12.1606743,1.42195434 12.3427168,1.77390321 12.430704,2.16226058 C12.523748,2.5688222 12.5257707,3.05528027 12.4347494,3.64894114 L12.4286813,3.69141773 L12.4286813,4.07168433 L12.7148926,4.23855663 C12.9566047,4.37104313 13.1477493,4.52173388 13.2943947,4.69568562 C13.5401521,4.98391961 13.6989336,5.35002734 13.7656825,5.78490669 C13.8344541,6.23192221 13.8111931,6.7638909 13.6979222,7.36564256 C13.5674584,8.05841548 13.3560868,8.66117848 13.0708869,9.15572732 C12.8209854,9.59485358 12.482421,9.97715928 12.0767325,10.2783229 C11.6984886,10.5564434 11.2474277,10.767815 10.73872,10.9023241 C10.192227,11.0416178 9.62988124,11.1089361 9.0659515,11.1025709 L8.668492,11.1025709 C8.38218215,11.1033498 8.1058651,11.2079119 7.89076591,11.396873 C7.67297575,11.5912719 7.5298955,11.8556126 7.48622698,12.1442587 L7.45588656,12.3121423 L6.95324694,15.6030665 L6.92998595,15.7244282 C6.92391787,15.761848 6.91380439,15.7810636 6.89863418,15.7952225 C6.88386187,15.8075307 6.86527169,15.8144381 6.84604412,15.8144381 L4.39150417,15.8144381 L4.39150417,15.8134268 Z" id="Path" fill="#253B80"></path>
                    <path d="M12.2162984,4.04538929 C12.2011282,4.14652403 12.1839353,4.24867011 12.1647197,4.35486158 C11.4911624,7.92491763 9.18630183,9.15876136 6.24226977,9.15876136 L4.74446438,9.15876136 C4.38025524,9.16288674 4.07396148,9.43304472 4.02438509,9.79388748 L3.25778382,14.8172496 L3.04034414,16.2412267 C3.02120544,16.3536897 3.05195626,16.4689142 3.12458652,16.5568862 C3.19721678,16.6448581 3.30453555,16.6968676 3.41858804,16.699367 L6.07640881,16.699367 C6.39555601,16.6954818 6.66407038,16.4591892 6.70850089,16.143126 L6.73479592,16.0035601 L7.2344015,12.724772 L7.26676461,12.5447522 C7.31074076,12.2282881 7.57937625,11.9914591 7.89885669,11.9874998 L8.29631619,11.9874998 C10.8712065,11.9874998 12.887833,10.9083922 13.4764372,7.78434035 C13.7232059,6.4797023 13.5957762,5.39048124 12.9444685,4.62489131 C12.7392888,4.3887537 12.4924677,4.19232524 12.2162984,4.04538929 Z" id="Path" fill="#179BD7"></path>
                    <path d="M11.533639,3.28384476 C11.319015,3.21951057 11.1004417,3.16917452 10.8792972,3.13315401 C10.4421511,3.0639184 10.0000492,3.03076922 9.5574663,3.03404197 L5.55354224,3.03404197 C5.45774064,3.03395911 5.36322755,3.05611602 5.27744442,3.0987682 C5.08624022,3.19544881 4.95414891,3.37924443 4.92347286,3.59129435 L4.07090707,9.15977271 L4.04663473,9.32259963 C4.09524019,8.96133059 4.40120977,8.69021831 4.76570268,8.68545082 L6.26451941,8.68545082 C9.20754012,8.68545082 11.513412,7.45160708 12.185958,3.88256238 C12.2061849,3.77738225 12.2233778,3.67422483 12.2385481,3.57410144 C12.014743,3.45219114 11.778396,3.35487176 11.533639,3.28384476 L11.533639,3.28384476 Z" id="Path" fill="#222D65"></path>
                    <path d="M5.23699053,4.32856655 C5.26702757,4.11600923 5.39926065,3.93163853 5.59096209,3.83502905 C5.67716063,3.79264401 5.77201686,3.770834 5.86807126,3.77131417 L9.87199532,3.77131417 C10.3463172,3.77131417 10.788276,3.80266594 11.1928149,3.87042621 C11.4663678,3.91482466 11.7359149,3.98111227 11.9988587,4.06865028 C12.1980942,4.13641055 12.3831707,4.21731834 12.5530771,4.31036229 C12.7533238,2.99055404 12.5510544,2.09247761 11.8603042,1.27935436 C11.0987596,0.383300636 9.72332726,0 7.96358292,0 L2.85627893,0 C2.49112614,0.00456533406 2.18417324,0.275406129 2.13417694,0.637148814 L0.00630217232,14.5583447 C-0.0154742805,14.6869837 0.0197752802,14.8187334 0.10287867,14.9193118 C0.18598206,15.0198902 0.308721319,15.0793512 0.439158827,15.0822226 L3.59355113,15.0822226 L4.38543608,9.89603356 L5.23699053,4.3275552 L5.23699053,4.32856655 Z" id="Path" fill="#253B80"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default PayPaypal