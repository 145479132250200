import React from 'react'

const PayAfter = () => (
<svg width="40px" height="28px" viewBox="0 0 40 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-1422.000000, -281.000000)" fillRule="nonzero">
            <g id="Group-11" transform="translate(1422.000000, 281.000000)">
                <path d="M3.17647237,26.6876261 C2.33163843,26.6954565 1.51908654,26.3633683 0.921672145,25.765954 C0.324257747,25.1685396 -0.007830426,24.3559877 0,23.5111537 L0,3.17647237 C-0.007830426,2.33163843 0.324257747,1.51908654 0.921672145,0.921672145 C1.51908654,0.324257747 2.33163843,-0.007830426 3.17647237,0 L36.2201757,0 C37.0650096,-0.007830426 37.8775615,0.324257747 38.4749759,0.921672145 C39.0723903,1.51908654 39.4044785,2.33163843 39.396648,3.17647237 L39.396648,23.5111537 C39.4044785,24.3559877 39.0723903,25.1685396 38.4749759,25.765954 C37.8775615,26.3633683 37.0650096,26.6954565 36.2201757,26.6876261 L3.17647237,26.6876261 L3.17647237,26.6876261 Z" id="Path" fill="#B2FCE4"></path>
                <g id="Group" transform="translate(12.000000, 7.000000)" fill="#000000">
                    <path d="M13.9466561,2.68638314 L11.8610616,1.49289522 L9.74532715,0.281323317 C8.34689658,-0.520363291 6.59885145,0.486265966 6.59885145,2.10169518 L6.59885145,2.37294579 C6.59885145,2.52363647 6.6772154,2.66227116 6.8098221,2.73460711 L7.7923486,3.29518309 C8.06358998,3.451911 8.40114855,3.25299635 8.40114855,2.93954976 L8.40114855,2.29458183 C8.40114855,1.97511648 8.74472589,1.77620183 9.02200449,1.93292051 L10.9508711,3.0420257 L12.8737189,4.14509367 C13.1509975,4.30181235 13.1509975,4.70567888 12.8737189,4.86239756 L10.9508711,5.96547476 L9.02200449,7.07457073 C8.74472589,7.23129864 8.40114855,7.03238399 8.40114855,6.7129094 L8.40114855,6.39344405 C8.40114855,4.77800561 6.65310342,3.76534836 5.25467285,4.57306296 L3.1389384,5.78463487 L1.05334392,6.97813202 C-0.351114641,7.78584662 -0.351114641,9.81718913 1.05334392,10.6249037 L3.1389384,11.8184009 L5.25467285,13.0299728 C6.65310342,13.8316594 8.40114855,12.8250301 8.40114855,11.2095917 L8.40114855,10.9383503 C8.40114855,10.7876504 8.3227846,10.6490157 8.1901779,10.5766798 L7.2076514,10.0161038 C6.93641002,9.8593851 6.59885145,10.0582998 6.59885145,10.3717371 L6.59885145,11.016705 C6.59885145,11.3361796 6.25527411,11.5350943 5.97799551,11.3783756 L4.04912894,10.2692704 L2.12628114,9.1661932 C1.84900253,9.00947452 1.84900253,8.60561722 2.12628114,8.44889854 L4.04912894,7.34582134 L5.97799551,6.23671614 C6.25527411,6.07999747 6.59885145,6.27891212 6.59885145,6.5983867 L6.59885145,6.91785205 C6.59885145,8.53328126 8.34689658,9.54593852 9.74532715,8.73822391 L11.8610616,7.526652 L13.9466561,6.33316409 C15.3511146,5.51942149 15.3511146,3.49409775 13.9466561,2.68638314 Z" id="Path"></path>
                </g>
                <path d="M35.7030983,1.23127673 C37.0573485,1.23127673 38.1653713,2.33929956 38.1653713,3.6935497 L38.1653713,23.3917335 C38.1653713,24.7459836 37.0573485,25.8540065 35.7030983,25.8540065 L3.6935497,25.8540065 C2.33929956,25.8540065 1.23127673,24.7459836 1.23127673,23.3917335 L1.23127673,3.6935497 C1.23127673,2.33929956 2.33929956,1.23127673 3.6935497,1.23127673 L35.7030983,1.23127673 Z M35.7030983,0.000140239305 L3.6935497,0.000140239305 C1.72373132,0.000140239305 0.000140239305,1.60061767 0.000140239305,3.6935497 L0.000140239305,23.3917335 C0.000140239305,25.4846655 1.60061767,27.0851429 3.6935497,27.0851429 L35.7030983,27.0851429 C37.7960304,27.0851429 39.3965078,25.4846655 39.3965078,23.3917335 L39.3965078,3.6935497 C39.3965078,1.60061767 37.7960304,0.000140239305 35.7030983,0.000140239305 Z" id="Shape" fill="#99E6CD"></path>
            </g>
        </g>
    </g>
</svg>
)

export default PayAfter