import React from 'react'

const PayMastercard = () => (
<svg width="40px" height="28px" viewBox="0 0 40 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
        <polygon id="path-1" points="0 27.0851955 0 0 39.396648 0 39.396648 27.0851955"></polygon>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-1422.000000, -242.000000)">
            <g id="Group-3" transform="translate(1422.000000, 242.000000)">
                <path d="M3.69343575,26.4696229 C1.9698324,26.4696229 0.615572626,25.1153631 0.615572626,23.3917598 L0.615572626,3.69343575 C0.615572626,1.9698324 1.9698324,0.615572626 3.69343575,0.615572626 L35.7032123,0.615572626 C37.4268156,0.615572626 38.7810754,1.9698324 38.7810754,3.69343575 L38.7810754,23.3917598 C38.7810754,25.1153631 37.4268156,26.4696229 35.7032123,26.4696229 L3.69343575,26.4696229 Z" id="Path" fill="#F2F2F2" fillRule="nonzero"></path>
                <g id="Clipped">
                    <mask id="mask-2" fill="white">
                        <use ></use>
                    </mask>
                    <g id="a"></g>
                    <path d="M35.7032123,0 L3.69343575,0 C1.72360335,0 0,1.60048883 0,3.69343575 L0,23.3917598 C0,25.4847067 1.60048883,27.0851955 3.69343575,27.0851955 L35.7032123,27.0851955 C37.7961592,27.0851955 39.396648,25.4847067 39.396648,23.3917598 L39.396648,3.69343575 C39.396648,1.60048883 37.7961592,0 35.7032123,0 M35.7032123,1.23114525 C37.0574721,1.23114525 38.1655028,2.33917598 38.1655028,3.69343575 L38.1655028,23.3917598 C38.1655028,24.7460196 37.0574721,25.8540503 35.7032123,25.8540503 L3.69343575,25.8540503 C2.33917598,25.8540503 1.23114525,24.7460196 1.23114525,23.3917598 L1.23114525,3.69343575 C1.23114525,2.33917598 2.33917598,1.23114525 3.69343575,1.23114525 L35.7032123,1.23114525" id="Shape" fill="#D8D8D8" fillRule="nonzero" mask="url(#mask-2)"></path>
                </g>
                <g id="Group" transform="translate(8.294031, 6.220523)" fillRule="nonzero">
                    <g id="Group-2">
                        <circle id="Oval" fill="#CE4549" cx="7.25727727" cy="7.25727727" r="7.25727727"></circle>
                        <circle id="Oval" fill="#FFAA2E" cx="15.5513084" cy="7.25727727" r="7.25727727"></circle>
                        <path d="M14.5145545,7.25727727 C14.5145545,4.76906792 13.2704499,2.59188474 11.4042929,1.34778006 C9.53813584,2.69556013 8.29403117,4.87274331 8.29403117,7.25727727 C8.29403117,9.64181123 9.53813584,11.9226698 11.4042929,13.1667745 C13.2704499,11.9226698 14.5145545,9.74548662 14.5145545,7.25727727 Z" id="Path" fill="#FF7733"></path>
                        <polygon id="Path" fill="#FFFFFF" points="4.14701558 6.22052338 18.6615701 6.22052338 18.6615701 8.29403117 4.14701558 8.29403117"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default PayMastercard