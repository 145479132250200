import React from 'react';

// Alphabetical order please

import AddReview from './addReview';
import AfterPayArrows from './afterpay-arrows';
import AfterPayLogo from './afterpay-logo';
import ArrowDown from './arrowDown';
import ArrowLeft from './arrowLeft';
import ArrowLeftDropshadow from './arrowLeftDropshadow';
import ArrowRight from './arrowRight';
import ArrowRightDropshadow from './arrowRightDropshadow';
import Bag from './bag';
import Bin from './bin';
import Close from './close';
import CloudCheck from './cloudCheck';
import CaretDown from './caretDown';
import Currency from './currency';
import Edit from './edit';
import Equaliser from './equaliser';
import Facebook from './facebook';
import GiftBox from './gift-box';
import Hamburger from './hamburger';
import Heart from './heart';
import HelpCircle from './help-circle';
import Instagram from './instagram';
import Linkedin from './linkedin';
import MultiColourCloudCheck from './multiColourCloudCheck';
import PayAfterpay from './pay-After';
import PayApple from './pay-Apple';
import PayGoogle from './pay-Google';
import PayMastercard from './pay-Mastercard';
import PayPaypal from './pay-Paypal';
import PayVisa from './pay-Visa';
import PayZip from './pay-Zip';
import Pinterest from './pinterest';
import QuickView from './quickView';
import Search from './search';
import Star from './star';
import Starburst from './starburst';
import Starburst2 from './starburst2';
import Tag from './tag';
import Tag2 from './tag2';
import Tick from './tick';
import Translate from './translate';
import Twitter from './twitter';
import User from './user';
import View from './view';
import WhiteBag from './whiteBag';
import Youtube from './youtube';

function Icon(props) {
  switch (props.symbol) {
    case 'addReview':
      return <AddReview />;
    case 'afterpayArrows':
      return <AfterPayArrows />;
    case 'afterPayLogo':
      return <AfterPayLogo />;
    case 'arrowDown':
      return <ArrowDown />;
    case 'arrowLeft':
      return <ArrowLeft />;
    case 'arrowLeftDropshadow':
      return <ArrowLeftDropshadow />;
    case 'arrowRight':
      return <ArrowRight />;
    case 'arrowRightDropshadow':
      return <ArrowRightDropshadow />;
    case 'bag':
      return <Bag />;
    case 'whiteBag':
      return <WhiteBag />;
    case 'bin':
      return <Bin />;
    case 'close':
      return <Close />;
    case 'cloudCheck':
      return <CloudCheck />;
    case 'caretDown':
      return <CaretDown />;
    case 'currency':
      return <Currency />;
    case 'edit':
      return <Edit />;
    case 'equaliser':
      return <Equaliser />;
    case 'facebook':
      return <Facebook />;
    case 'giftbox':
      return <GiftBox />;
    case 'hamburger':
      return <Hamburger />;
    case 'heart':
      return <Heart />;
    case 'helpCircle':
      return <HelpCircle />;
    case 'instagram':
      return <Instagram />;
    case 'linkedin':
      return <Linkedin />;
    case 'multiColourCloudCheck':
      return <MultiColourCloudCheck />;
    case 'afterpay':
      return <PayAfterpay />;
    case 'apple':
      return <PayApple />;
    case 'google':
      return <PayGoogle />;
    case 'mastercard':
      return <PayMastercard />;
    case 'paypal':
      return <PayPaypal />;
    case 'visa':
      return <PayVisa />;
    case 'pinterest':
      return <Pinterest />;
    case 'quickView':
      return <QuickView />;
    case 'search':
      return <Search />;
    case 'star':
      return <Star />;
    case 'starburst':
      return <Starburst />;
    case 'starburst2':
      return <Starburst2 />;
    case 'tag':
      return <Tag />;
    case 'tag2':
      return <Tag2 />;
    case 'tick':
      return <Tick />;
    case 'translate':
      return <Translate />;
    case 'twitter':
      return <Twitter />;
    case 'user':
      return <User />;
    case 'view':
      return <View />;
    case 'youtube':
      return <Youtube />;
    default:
      return <span>Unknown icon: {props.symbol}</span>;
  }
}

export default Icon;
